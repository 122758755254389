<template>
  <div>
    <h4 v-if="categoryName" class="category-title">
      {{ categoryName }} - choose prod
    </h4>
    <b-list-group v-if="isLoaded && sortedProducts.length > 0" class="w-100 pointer product-categories-list mb-4">
      <selection-cards
        :items="sortedProducts"
        @chosen="route"
      />
    </b-list-group>
  </div>
</template>

<script>
  import SelectionCards from '@/components/shared/SelectionCards'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ChooseProduct',
    components: {
      SelectionCards,
    },
    data() {
      return {
        isLoaded: false,
      }
    },
    computed: {
      ...mapGetters('products', [
        'productBuckets',
        'products',
      ]),
      ...mapGetters('companies', ['find']),
      company() {
        return this.find(this.routeParams.companyId)
      },
      routeParams() {
        return this.$store.state.appRouter.params
      },
      // if no products pull products
      passedProducts() { return this.routeParams.products },
      sortedProducts() {
        return this.passedProducts ? this.passedProducts : this.products
      },
      categoryIdBucketId() {
        return `${this.routeParams.bucketId}-${this.routeParams.categoryId}`
      },
      categoryName() {
        return this.routeParams.categoryName ? this.routeParams.categoryName : (this.routeParams.bucketId ? this.productBuckets.find(x => x.id === this.routeParams.bucketId).name : '')
      },
    },
    async mounted() {
      this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })
      if (!this.products?.length) {
        await this.getProducts(this.routeParams)
      }
      this.isLoaded = true
    },
    methods: {
      ...mapActions('products', ['getProducts']),
      async route(product=null) {
        if (product) {
          this.product = product
          await this.$router.push({
            name: 'product-details',
            params: {
              companyId: this.routeParams.companyId,
              bucketId: this.routeParams.bucketId,
              categoryId: this.routeParams.categoryId,
              productId: product.id,
              productKind: product.kind,
              product: product,
            },
          })
        }
      },
    },
  }
</script>

<style lang="scss">

.category-title {
  text-align: center;
}

.product-categories-list {
  padding-top: 1rem;
}

</style>
